<template>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <div class="header-content">
            <div class="header-left">
              <div class="brand-logo">
                <a class="mini-logo" href="./">
                  <img src="images/logo.png" alt="" width="40" />
                </a>
              </div>
            </div>
            <div class="header-right">
              <!-- <div class="dark-light-toggle theme-switch" onclick="themeToggle()">
                <span class="dark"><i class="ri-moon-line"></i></span>
                <span class="light"><i class="ri-sun-line"></i></span>
              </div> -->

              <div class="dark-light-toggle theme-switch">
                <a @click="addNftcToMM" class="btn btn-outline-secondary btn-sm"
                  ><img src="images/metamask-icon-24.png" /> Add
                  {{ tokenName["nftc"] }}</a
                >
              </div>

              <!-- Don't show button, because EXP token is NOT TRADABLE!!! -->
              <!-- <div class="dark-light-toggle theme-switch">
                <a @click="addExpToMM" class="btn btn-outline-secondary btn-sm"
                  ><img src="images/metamask-icon-32.png" /> Add EXP</a
                >
              </div> -->

              <div
                class="dark-light-toggle theme-switch"
                v-if="!address['wallet']"
              >
                <a @click="loginMM" class="btn btn-primary btn-sm"
                  ><img src="images/metamask-icon-32.png" />Login</a
                >
              </div>

              <div class="dropdown profile_log dropdown">
                <div
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  class=""
                  aria-expanded="false"
                >
                  <div class="user icon-menu active logged-in-addr-text">
                    <span>{{ address["walletShort"] }}</span>
                  </div>
                </div>
                <div
                  tabindex="-1"
                  role="menu"
                  aria-hidden="true"
                  class="dropdown-menu dropdown-menu-right"
                >
                  <a class="dropdown-item" href="./ExpShop">
                    <span><i class="ri-shopping-cart-2-fill"></i></span>EXP Shop
                  </a>

                  <a class="dropdown-item" href="./pixelguy">
                    <span><i class="ri-user-fill"></i></span>My PGC NFTs
                  </a>
                  <a class="dropdown-item" href="./shorai">
                    <span><i class="ri-sword-line"></i></span>My Shorai NFTs
                  </a>
                  <a class="dropdown-item" href="./kensho">
                    <span><i class="ri-fire-fill"></i></span>My Kensho NFTs
                  </a>
                  <a class="dropdown-item" href="./kenshoX">
                    <span><i class="ri-fire-line"></i></span>My Kensho Exclusive
                    NFTs
                  </a>
                  <a class="dropdown-item" href="./nftLookup">
                    <span><i class="ri-search-2-line"></i></span>Look up any
                    NFTClub NFT
                  </a>
                  <a
                    class="dropdown-item"
                    :href="blockExplorer + '/address/' + address['staking']"
                    target="_blank"
                  >
                    <span><i class="ri-eye-line"></i></span>View Staking
                    Contract Activity
                  </a>
                  <a
                    class="dropdown-item"
                    :href="blockExplorer + 'address/' + address['leveler']"
                    target="_blank"
                  >
                    <span><i class="ri-eye-line"></i></span>View Leveling
                    Contract Activity
                  </a>

                  <a
                    class="dropdown-item"
                    :href="nftMarketPlace + '/collection/' + address['pgc']"
                    target="_blank"
                  >
                    <span><i class="ri-store-2-line"></i></span>Pixelguy Club on
                    Oasis
                  </a>

                  <a
                    class="dropdown-item"
                    :href="nftMarketPlace + '/collection/' + address['shorai']"
                    target="_blank"
                  >
                    <span><i class="ri-store-2-line"></i></span>Shorai on Oasis
                  </a>

                  <a
                    class="dropdown-item"
                    :href="nftMarketPlace + '/collection/' + address['kensho']"
                    target="_blank"
                  >
                    <span><i class="ri-store-2-line"></i></span>Kensho on Oasis
                  </a>

                  <a
                    class="dropdown-item"
                    :href="nftMarketPlace + '/collection/' + address['kenshoX']"
                    target="_blank"
                  >
                    <span><i class="ri-store-2-line"></i></span>Kensho Exclusive
                    on Oasis
                  </a>

                  <!-- <a
                    class="dropdown-item"
                    :href="nftRarity + 'Kensho'"
                    target="_blank"
                  >
                    <span><i class="ri-search-fill"></i></span>
                    Kensho on nftrarity.cash
                  </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar">
    <div class="brand-logo">
      <a class="full-logo" href="./"
        ><img src="images/logo.png" alt="" width="40"
      /></a>
    </div>
    <div class="menu">
      <ul>
        <li>
          <a href="./ExpShop">
            <span><i class="ri-shopping-cart-2-fill"></i></span>
            <span class="nav-text">EXP Shop</span>
          </a>
        </li>

        <!-- <a
          class="dropdown-item mt-0"
          href="https://app.mistswap.fi/swap?inputCurrency=BCH&outputCurrency=0xE7B1076d0853a7aAa6FbB477886726ed03C6Fffd"
          target="_blank"
        >
          Trade {{ tokenName["nftc"] }} on MistSwap
        </a>

        <a
          class="dropdown-item"
          :href="'https://dexscreener.com/' + netName + '/' + address['nftc']"
          target="_blank"
        >
          Check the {{ tokenName["nftc"] }} price on Dexscreener
        </a> -->

        <li>
          <a href="./pixelguy">
            <span><i class="ri-user-fill"></i></span>
            <span class="nav-text">Pixelguy</span>
          </a>
        </li>
        <li>
          <a href="./shorai">
            <span><i class="ri-sword-fill"></i></span>
            <span class="nav-text">Shorai</span>
          </a>
        </li>
        <li>
          <a href="./kensho">
            <span><i class="ri-fire-fill"></i></span>
            <span class="nav-text">Kensho</span>
          </a>
        </li>
        <li>
          <a href="./kenshoX">
            <span><i class="ri-fire-line"></i></span>
            <span class="nav-text">Kensho Exclusive</span>
          </a>
        </li>

        <li>
          <a href="./nftLookup">
            <span><i class="ri-search-2-line"></i></span>
            <span class="nav-text">Look up any NFTClub NFT</span>
          </a>
        </li>

        <!-- !!!Only testnet!!! -->
        <!-- <li>
          <a href="./NftFaucet">
            <span><i class="ri-gift-fill"></i></span>
            <span class="nav-text">NFT Faucet</span>
          </a>
        </li> -->

        <!-- <li>
          <a href="./admin">
            <span><i class="ri-settings-3-fill"></i></span>
            <span class="nav-text">Admin</span>
          </a>
        </li> -->
      </ul>
    </div>
  </div>

  <!-- Popup -->
  <Popup ref="popup" />
</template>

<script>
import { ethers } from "ethers";

import Popup from "./Popup.vue";

export default {
  components: { Popup },

  data() {
    return {
      address: {
        wallet: null,
        walletShort: null,
        staking: window.address["staking"],
        leveler: window.address["leveler"],
        nftc: window.address["nftc"],
        kimon: window.address["kimon"],
        kimonOld: window.address["kimonOld"],
      },

      tokenName: window.tokenName,

      blockExplorer: window.blockExplorer,
      netName: window.netName,

      nftMarketPlace: window.nftMarketPlace,
      nftRarity: window.nftRarity,
    };
  },

  mounted: function () {
    this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    this.provider.getNetwork().then((network) => {
      if (network.chainId !== window.netId) {
        this.showPopup(
          "Wrong Network!",
          `Please switch to ${window.netName} and reload this site`,
          0
        );
        window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: window.netId0x }],
          })
          .catch((err) => {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
              // dispatch(connectFailed(`Add ${window.netName} to Metamask and then reload this website.`));
              window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainName: window.netName,
                    chainId: window.netId0x,
                    nativeCurrency: {
                      name: window.tokenName["eth"],
                      decimals: 18,
                      symbol: window.tokenName["eth"],
                    },
                    rpcUrls: [window.rpc],
                    blockExplorerUrls: [window.blockExplorer],
                  },
                ],
              });
            }
          });
      }
    });
    this.provider.on("network", (n, o) => {
      if (o) {
        window.location.reload();
      }
    });
    this.signer = this.provider.getSigner();
    this.signer.getAddress().then((address) => {
      this.address["wallet"] = address;
      this.address["walletShort"] =
        address.substring(0, 5) +
        "..." +
        address.substring(address.length - 5, address.length);
    });
  },
  methods: {
    showPopup(_title, _text, _timeOut) {
      this.$refs.popup.show(_title, _text, _timeOut);
    },

    loginMM: function () {
      window.location.reload();
    },

    addExpToMM: function () {
      window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: window.address["expToken"],
            symbol: "EXP",
            decimals: 0,
          },
        },
      });
    },

    addNftcToMM: function () {
      window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: window.address["nftc"],
            symbol: window.tokenName["nftc"],
            decimals: 18,
          },
        },
      });
    },
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
</style>