<template>
  <Layout />
  <router-view />
</template>

<script>
// import Layout from "@/components/LayoutDoge";
import Layout from "@/components/LayoutSbch";

export default {
  components: { Layout },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}

select,
#app select:hover {
  outline: 1px solid #6f4ef2;
}

.logged-in-addr-text {
  background: #6f4ef2;
  background: -webkit-linear-gradient(to right, #6f4ef2 0%, #802cb0 100%);
  background: -moz-linear-gradient(to right, #6f4ef2 0%, #802cb0 100%);
  background: linear-gradient(to right, #6f4ef2 0%, #802cb0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.top-bid button.harvest-button {
  margin: 0;
}

.back-to-top {
  position: fixed;
  bottom: 48px;
  right: 22px;
  width: 64px;
  height: 64px;
  z-index: 9999;
  cursor: pointer;
  text-decoration: none;
  opacity: 0.75;
  transition: opacity 0.2s ease-in;
  background-image: url(../public/images/top.png);
  display: none;
}
.back-to-top:hover {
  transform: scale(1.05);
  opacity: 1;
}
.back-to-top:active {
  transform: scale(0.9);
  opacity: 1;
}
</style>
